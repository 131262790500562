import React, { FC, ReactElement } from 'react';
import { TodoList } from '../models/todoList';

interface SidebarProps {
    selectedList?: TodoList
    lists?: TodoList[];
    onListCreate: (list: TodoList) => void
}

const Sidebar: FC<SidebarProps> = (props: SidebarProps): ReactElement => {
    return (
        <div>
           
        </div>
    );
};

export default Sidebar;