import { createTheme } from '@fluentui/react';

export const DarkTheme = createTheme({
    palette: {
        themePrimary: '#0392ff',
        themeLighterAlt: '#00060a',
        themeLighter: '#001729',
        themeLight: '#012c4d',
        themeTertiary: '#025799',
        themeSecondary: '#0280e0',
        themeDarkAlt: '#1c9dff',
        themeDark: '#3facff',
        themeDarker: '#72c2ff',
        neutralLighterAlt: '#323232',
        neutralLighter: '#3a3a3a',
        neutralLight: '#484848',
        neutralQuaternaryAlt: '#505050',
        neutralQuaternary: '#575757',
        neutralTertiaryAlt: '#747474',
        neutralTertiary: '#ececec',
        neutralSecondary: '#efefef',
        neutralPrimaryAlt: '#f2f2f2',
        neutralPrimary: '#e3e3e3',
        neutralDark: '#f9f9f9',
        black: '#fcfcfc',
        white: '#292929',
    }
});
